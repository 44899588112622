import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { getUtm, removeUtm } from '~/utils/routeUtm';
import { getAdvcakeTrack } from '~/utils/advCake';
import { getMatomoAttributes } from '~/utils/matomo';
import { getVisitHistory, clearVisitHistory } from '@/utils/visitHistory';
import * as scheme from './scheme/scheme.gql';

export const getUser = (data) => {
  if (data.student) {
    return {
      ...data.student,
      role: 'student',
      startedControlWorkLessonSessions: data.startedControlWorkLessonSessions,
    };
  }
  if (data.teacher) {
    return {
      ...data.teacher,
      role: 'teacher',
      startedControlWorkLessonSessions: data.startedControlWorkLessonSessions,
    };
  }
  if (data.teacherCurator) {
    return {
      ...data.teacherCurator,
      role: 'teacher',
      startedControlWorkLessonSessions: data.startedControlWorkLessonSessions,
    };
  }
  if (data.schoolAdmin) {
    return {
      ...data.schoolAdmin,
      role: 'administrator',
      startedControlWorkLessonSessions: data.startedControlWorkLessonSessions,
    };
  }
  if (data.parent) {
    return {
      ...data.parent,
      role: 'parent',
      startedControlWorkLessonSessions: data.startedControlWorkLessonSessions,
    };
  }
  if (data.user) {
    return {
      ...data.user,
      role: 'unselected',
      startedControlWorkLessonSessions: data.startedControlWorkLessonSessions,
    };
  }
  return false;
};

async function getFingerPrint() {
  const fp = await FingerprintJS.load();
  const { visitorId } = await fp.get();
  return visitorId;
}

export default class {
  constructor(apolloClient, app) {
    this.apolloClient = apolloClient;
    this.app = app;
  }

  query(...args) {
    return this.apolloClient.query.apply(this, args).catch((data) => {
      if (data?.networkError?.statusCode === 403) {
        this.app.store.dispatch('logout');
      }
      return data;
    });
  }

  mutate(...args) {
    return this.apolloClient.mutate.apply(this, args)
      .catch((data) => {
        if (data?.networkError?.statusCode === 403) {
          this.app.store.dispatch('logout');
        }
        return data;
      });
  }

  /**
   * Авторизация
   */
  async login(email, password) {
    await this.app.store.dispatch('logout');

    try {
      localStorage.removeItem('school-vuex');
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('localStorage не доступен');
    }

    const fingerprint = await getFingerPrint();

    return this.mutate({
      mutation: scheme.LOGIN,
      variables: {
        email,
        fingerprint,
        password,
      },
    })
      .then(({ data }) => (
        data?.signIn?.success ? getUser(data.signIn) : false
      ));
  }

  async register({
    name,
    email,
    phone,
    emailMe = true,
    classNumber = null,
    landCode = 'school_synergy_ru',
    formTitle = '',
    comment = '',
  }) {
    await this.app.store.dispatch('logout');
    localStorage.removeItem('REGISTER_PAGE');
    localStorage.removeItem('REGISTER_USER_ID');

    const fingerprint = await getFingerPrint();

    const utmData = getUtm(this.app?.context?.route);
    Object.assign(utmData, getAdvcakeTrack(this.app?.$cookies));

    const yandexClientId = await this.app?.$yaMetrika?.getClientID();
    const customAttributes = {
      yandex_client_id: yandexClientId,
    };
    Object.assign(customAttributes, getMatomoAttributes(this.app?.$cookies));

    return this.mutate({
      mutation: scheme.REGISTER,
      variables: {
        classNumber,
        comment,
        customAttributes,
        email,
        emailMe,
        fingerprint,
        formTitle,
        landCode,
        name,
        phone,
        type: 'student',
        utmData,
        visitHistory: getVisitHistory(),
      },
    })
      .then(({ data }) => {
        if (data?.signUp?.success) {
          clearVisitHistory();
          removeUtm();
          localStorage.setItem('directFlow', data.signUp.directFlowToken);
          localStorage.setItem('lastLeadId', data.signUp.user.registrationLeadId);
        }

        return data?.signUp || {};
      });
  }

  /**
   * Восстановить пароль
   */
  async remember(email) {
    await this.app.store.dispatch('logout');

    return this.mutate({
      mutation: scheme.REMEMBER,
      variables: {
        email,
      },
    });
  }

  /**
   * Информация о зарегистрированном пользователе
   */
  getMe(clearCache = false) {
    const param = { query: scheme.GET_ME };
    if (clearCache) {
      param.fetchPolicy = 'network-only';
    }

    try {
      localStorage.removeItem('school-vuex');
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('localStorage не доступен');
    }

    return this.query(param)
      .then((response) => (response?.data?.me ? getUser(response.data.me) : false));
  }

  updateAccount(attributes) {
    return this.mutate({
      mutation: scheme.UPDATE_ACCOUNT,
      variables: { attributes },
    })
      .then((response) => response?.data?.updateAccount || {});
  }

  async refreshToken() {
    const fingerprint = await getFingerPrint();

    const fetchBody = {
      grant_type: 'refresh_token',
      scope: 'user',
      refresh_token: localStorage.getItem('refreshToken'),
      fingerprint,
    };

    return fetch(`${process.env.graphql}/token`, {
      method: 'POST',
      body: JSON.stringify(fetchBody),
      headers: { 'Content-type': 'application/json; charset=UTF-8' },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if (!data.access_token && !data.refresh_token) {
          throw new Error('Не удалось обновить токен');
        }

        if (data.access_token) {
          const cookieDomain = window?.location?.hostname || process.env.cookie_domain;
          await this.app.$cookies.set('auth', data.access_token, { domain: cookieDomain, path: '/' });
        }

        if (data.refresh_token) {
          await localStorage.setItem('refreshToken', data.refresh_token);
        }

        return data.access_token;
      })
      .catch(() => {
        this.app.store.dispatch('logout');
      });
  }

  /**
   * Сбросить токен авторизации
   */
  async logout() {
    const fingerprint = await getFingerPrint();
    return this.mutate({
      mutation: scheme.LOGOUT,
      variables: { fingerprint },
    });
  }

  /*
   landing requests
   */

  async sendLead({
    blankRegistration,
    name,
    phone,
    email,
    landCode,
    client,
    formTitle,
    comments,
    visitHistory,
  }) {
    await this.app.store.dispatch('logout');

    if (phone) {
      // eslint-disable-next-line no-param-reassign
      phone = phone.replace(/^77/, '7');
    }

    const utmData = getUtm(this.app?.context?.route);
    Object.assign(utmData, getAdvcakeTrack(this.app?.$cookies));

    const yandexClientId = await this.app?.$yaMetrika?.getClientID();
    const customAttributes = {
      yandex_client_id: yandexClientId,
    };
    Object.assign(customAttributes, getMatomoAttributes(this.app?.$cookies));

    const args = {
      mutation: scheme.SEND_LEAD,
      variables: {
        blankRegistration,
        customAttributes,
        email: email || '',
        formTitle: formTitle || '',
        landCode: landCode || 'school_synergy_ru',
        latestComment: comments || '',
        name: name || '',
        phone: phone || '',
        utmData,
        visitHistory: visitHistory || [],
      },
    };
    if (client && client === 'kidsUploadLink') {
      args.context = {
        client,
      };
    }
    return this.mutate(args)
      .then((response) => {
        localStorage.setItem('lastLeadId', response.data?.sendLead?.lead?.id);

        if (blankRegistration) {
          localStorage.setItem('blankRegistration', '1');
        } else {
          localStorage.removeItem('blankRegistration');
        }

        if (comments) {
          localStorage.setItem('lastLeadComment', `${comments} /`);
        }

        removeUtm();

        return response.data?.sendLead || {};
      });
  }

  updateLead(comment, leadId) {
    const id = leadId || localStorage.getItem('lastLeadId');
    const lastLeadComment = localStorage.getItem('lastLeadComment') || '';
    return this.mutate({
      mutation: scheme.UPDATE_LEAD,
      variables: {
        id,
        comment: `${lastLeadComment} ${comment}`,
      },
    })
      .then((response) => {
        localStorage.removeItem('lastLeadId');
        localStorage.removeItem('lastLeadComment');
        return response.data?.updateLead || {};
      });
  }

  async finalizeLead({
    comments,
    email,
    formTitle,
    name,
    phone,
    visitHistory,
  }) {
    await this.app.store.dispatch('logout');
    const leadId = localStorage.getItem('lastLeadId');

    if (phone) {
      // eslint-disable-next-line no-param-reassign
      phone = phone.replace(/^77/, '7');
    }

    const args = {
      mutation: scheme.FINALIZE_LEAD,
      variables: {
        comment: comments || '',
        email: email || '',
        formTitle: formTitle || '',
        id: leadId,
        name: name || '',
        phone: phone || '',
        visitHistory: visitHistory || [],
      },
    };

    return this.mutate(args)
      .then((response) => {
        localStorage.removeItem('blankRegistration');
        return response.data?.finalizeLead || {};
      });
  }

  purchaseProduct({
    callbackSuccess,
    fastTrialFromCourseId,
    productId,
    promoCode,
    startTrial,
  }) {
    return this.mutate({
      mutation: scheme.PURCHASE_PRODUCT,
      variables: {
        callbackSuccess: callbackSuccess || '/',
        fastTrialFromCourseId: fastTrialFromCourseId || null,
        productId,
        promoCode: promoCode || '',
        startTrial: startTrial || false,
      },
    })
      .then((response) => response.data?.purchaseProduct || {});
  }

  emailSubscribe(email) {
    return this.mutate({
      mutation: scheme.EMAIL_SUBSCRIBE,
      variables: { email },
    })
      .then((response) => response.data?.sendInfoToEnkod || {});
  }

  confirmEmail(token) {
    return this.mutate({
      mutation: scheme.CONFIRM_EMAIL,
      variables: {
        confirmationToken: token,
      },
    })
      .then(({ data }) => data.confirmEmail);
  }

  getOnboardingQuestions(questionnarieName) {
    return this.query({
      query: scheme.GET_ONBOARDING_QUESTIONS,
      variables: {
        questionnarieName,
      },
    }).then((response) => response?.data?.getOnboardingQuestions);
  }

  getProducts() {
    return this.query({
      query: scheme.GET_PRODUCTS,
      variables: {
        kind: 'school',
      },
    }).then((response) => response?.data?.getProducts);
  }
}
