// https://jr.synergy.ru/browse/ESK-4824
export default function routeIsArsenal(routeName) {
  if (!routeName) return false;

  if (routeName === 'ege' || routeName === 'oge') return true;

  const arsenalPages = [
    'ege-probniki',
    'catalog-repetitor',
    'catalog-courses',
    'catalog-programmirovanie-dlya-detej',
    'privacy',
    'rekviziti',
  ];

  for (let i = 0; i < arsenalPages.length; i += 1) {
    if (routeName.includes(arsenalPages[i])) {
      return true;
    }
  }

  return false;
}
