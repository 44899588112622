/* eslint-disable */

export default (ctx, inject) => {
  let yaMetrikaId = 87303212;
  const ONLINE_SCHOOL_DOMAINS = [
    // 'localhost:3001',
    'online-school.syndev.ru',
    'online-school.synergy.ru',
  ];
  if (ONLINE_SCHOOL_DOMAINS.includes(location.host)) {
    yaMetrikaId = 97262625;
  }

  (function (m, e, t, r, i, k, a) {
    m[i] = m[i] || function () { (m[i].a = m[i].a || []).push(arguments); };
    m[i].l = 1 * new Date();
    for (var j = 0; j < document.scripts.length; j++) {
      if (document.scripts[j].src === r) { return; }
    }
    k = e.createElement(t), a = e.getElementsByTagName(t)[0], k.async = 1, k.src = r, a.parentNode.insertBefore(k, a);
  })(window, document, 'script', 'https://mc.yandex.ru/metrika/tag.js', 'ym');

  ym(yaMetrikaId, "init", {
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
    webvisor: true,
  });

  const head = document.getElementsByTagName('head');

  if (head && head[0]) {
    const noScript = document.createElement('noscript');
    noScript.innerHTML = `<img src="https://mc.yandex.ru/watch/${yaMetrikaId}" style="position:absolute; left:-9999px;" alt="yandex"/>`;

    head[0].append(noScript);
  }

  const metrika = {
    reachGoal: (target) => {
      ym(yaMetrikaId, 'reachGoal', target);
    },
    hit: (target) => {
      ym(yaMetrikaId, 'hit', target);
    },
    getClientID: async () => {
      let clientID = null;
      await ym(yaMetrikaId, 'getClientID', function(value) {
        clientID = value;
        return value;
      });
      return clientID;
    },
  };

  inject('yaMetrika', metrika);
  ctx.$yaMetrika = metrika;
}

/* eslint-enable */
