/**
 * https://jr.synergy.ru/browse/ESK-4768
 * https://jr.synergy.ru/browse/ESK-4826
 * https://jr.synergy.ru/browse/ESK-4865
 */

/* eslint-disable */
/*
function getMatomoUserId() {
  const paq = window._paq || [];
  let userId = null;

  paq.push([
    function () {
      userId = this.getUserId();
    },
  ]);

  return userId;
}

export const setMatomoUserId = () => {
  if (getMatomoUserId()) return;

  // eslint-disable-next-line no-restricted-globals
  const uniqueId = self.crypto.randomUUID();

  // eslint-disable-next-line no-underscore-dangle
  const paq = window._paq || [];
  paq.push(['setUserId', uniqueId]);
  paq.push(['trackPageView']);
};
 */

function getMatomoVisitorId() {
  return window.Matomo.getTracker().getVisitorId();
}

export const getMatomoAttributes = () => ({
  matomo_user_id: getMatomoVisitorId(),
});

export default {
  // setMatomoUserId,
  getMatomoAttributes,
};
