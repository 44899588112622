/**
 * https://jr.synergy.ru/browse/ESK-4719
 */
export const addAdvCakePage = (route = {}) => {
  if (!window) return;

  window.advcake_data = window.advcake_data || [];
  window.advcake_data.push({
    pageType: route.name === 'index' ? 1 : 2,
  });
};

export const getAdvcakeTrack = ($cookies) => {
  if (!$cookies) return {};

  return {
    advcake_track_id: $cookies.get('advcake_track_id'),
    advcake_track_url: $cookies.get('advcake_track_url'),
  };
};

export default {
  addAdvCakePage,
  getAdvcakeTrack,
};
