const hostPC = '/classroom';

export default {
  linkToCore(to = '/') {
    return hostPC + to;
  },
  /**
   * Перейти в ЛК
   */
  toCore() {
    window.location = hostPC;
  },
};
